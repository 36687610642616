@import "../stylesheets/fonts";
@import "../stylesheets/mainova_layout";


body {
  margin: 0;
  padding: 0;
}

nav.main-nav, .footer-about {
  a:visited {
    color: #0a3185;
  }
  a:hover {
    color: #33addd;
  }
}

footer {
  a:visited {
    color: #fff;
  }
  a:hover {
    color: #33addd;
  }
}

div.header {
  background-color: #0a3185;
  height: 66px;
  padding: 10px 0;
  width: 100%;
}

div.center-content {
  max-width: 1400px;
  margin: auto;
}

.main-navigation {
  margin: 0;
  float: right;
  max-width: 80%;
  margin-right: 60px;
}

.main-navigation a {
  display: block;
  text-decoration: none;
  color:#333
}

.main-navigation ul li a {
  font-size: 24px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  color: #ffffff;
  text-transform: none;
}

.main-navigation > ul > li a:hover, .main-navigation > ul > li.current a {
  color: #73d8ff;
}

.main-navigation .sf-arrows .sf-with-ul:after {
  border-color: #ffffff transparent transparent;
}

.main-navigation .sf-arrows ul .sf-with-ul:after {
  border-left-color: #ffffff !important;
}

.logo-top .main-navigation {
  float: none;
  width: auto;
  text-align: center;
  max-width: none;
  display:inline-block;
}

.main-navigation ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display:inline-block;
}

.main-navigation ul li {
  position:relative
}

.main-navigation > ul > li {
  float: left;
  position: relative;
  padding: 15px 0;
  font-size: 14px;
  margin-left: 25px;
}



.main-navigation ul ul {
  background: #FFF;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99999;
  font-size: 15px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
  display: none;
  min-width:160px
}

.main-navigation ul ul ul {
  left: 100%;
  top:0
}

.main-navigation ul ul a {
  padding: 10px 15px;
  color: #333 !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  white-space: nowrap;
  border-bottom:1px solid #F6F6F6
}

.main-navigation ul ul li {
  text-align:left
}

.main-navigation ul ul li:last-child {
  border:none
}

.main-navigation ul li:hover > ul {
  display:block
}

//.main-navigation ul ul li.current_page_item > a, .main-navigation ul ul li.current-menu-item > a, .main-navigation ul ul li > a:hover {
//  color: #FFF !important;
//  background: #E66432;
//  border-bottom:1px solid #E66432
//}

.logo {
  margin: 10px 30px;
  height: 45px;
}

div.topbild {
  overflow: hidden;

  div.inner {
    #hintergrund {
      height: auto;
      width: 100%;
    }

    #zeppelin {
      position: relative;
      top: -400;
      left: 50px;
      width: 60%;
      height: auto;
    }

    overflow: hidden;
  }
}

div.content {
  margin: 15px 0px;

  min-height: 50%;
  min-height: -webkit-calc(100% - 990px);
  min-height: -moz-calc(100% - 990px);
  min-height: calc(100% - 990px);
}

div.whitebox {
  background-color: white;
  padding: 30px;
  line-height: 1.3em;

  p {
    font-size: 15px;
  }
}

h1 {
  margin-top: 70px;
  margin-bottom: 0px;

  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 48px;
  text-align: center;

  color: #0a3185;
}

h2 {
  margin-top: 0;
  margin-bottom: 40px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 24px;
  text-align: center;

  color: #009fe3;
}

h3 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 24px;
  text-align: left;

  color: #0a3185;

  .date {
    font-weight: normal;
    font-style: italic;
  }
}

h3.red {
  color: #d82130;
}

h3.video {
  font-size: 20px;
}

h4 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 20px;
  text-align: left;

  margin-top: 0px;
  margin-bottom: 6px;

  color: #009fe3;
}

a {
  color: #d82130;
  text-decoration: none;
}

a:visited {
  color: #d82130;
  text-decoration: none;
}

a.bluelink {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 20px;
  text-align: left;

  margin-top: 0px;
  margin-bottom: 6px;

  color: #0a3185;

  &:hover {
    color: #d82130;
  }
}

div.bar {
  height: 25px;
  width: 100%;

  div {
    height: 25px;
  }
}

div.bar.gold {
  div {
    background-color: #c3943f;
  }
}

div.bar.silver {
  div {
    background-color: #9a9997;
  }
}

div.bar.bronze {
  div {
    background-color: #a57852;
  }
}

div.bar.other {
  div {
    background-color: #082b74;
  }
}

p.info {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 18px;
  text-align: left;

  margin-top: 6px;

  color: #0a3185;
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;

  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 1em;
}

a.detail-link {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
}

hr.spacer {
  width: 40px;
  text-align: left;
  margin-left: 0;
  margin-top: 2px;
  margin-bottom: 2px;

  height: 3px;
  border-width: 0;
  color: #009fe3;
  background-color: #009fe3;

}

div.landingpage {
  .center {
    margin: auto;
    width: 100%;
    max-width: 1200px;
    min-height: 500px;
  }

  .visual {
    padding-top: 12px;
    margin-right: 10px;
    h3 {
      margin-top: 15px;
    }
  }

  .countdown {
    table {
      color: #0a3185;
    }
  }
}

.center {
  margin: auto;
  width: 900px;
  min-height: 300px;
}

.titleonly {
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;


  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.visual {
  margin-right: 50px;
  margin-left: 20px;

  h3 {
    margin-bottom: 0;
  }
  img.yt_thumbnail {
    width: 230px;
  }
  img.teamfit_logo {
    width: 220px;
  }
  h4.teamfit_text {
    font-size: 16px;
  }
  p {
    width: 230px;
  }
}

.visual_2 {
  margin-right: 50px;
  margin-left: 20px;

  h3 {
    margin-bottom: 0;
  }
  img.yt_thumbnail {
    width: 230px;
  }
}

.text {
  width: -webkit-calc(100% - 720px);
  width: -moz-calc(100% - 720px);
  width: calc(100% - 720px);
  float: right;

  h3.first {
    margin-right: -250px;
  }
}

.sportswear1 {
  padding-top: 60px;
  float: right;
  max-width: 400px;

  img {
    margin-top: 30px;
    margin-left: 40px;
    width: 70%;
  }

  .energieakademie {
    margin-top: 20px;
    margin-left: 40px;
    width: 80%;
  }

  .form-links {
    margin-left: 40px;
    margin-top: 50px;

    div {
      display: inline-block;
      vertical-align: top;
    }

    img {
      width: 160px;
      padding-right: 10px;
      margin: 0;
    }
  }
}

.sportswear2 {
  display: none;
}

.socialbox {

  margin-top: 20px;
  margin-right: 40px;
  height: 39px;
  float: right;
  text-align: right;

  a {
    margin: 0 5px 5px 0;
    width: 26px;
    height: 26px;
    background-size: 39px;

    img {
      width: 26px;
      height: 26px;
      margin-bottom: -5px;
    }
  }
}

.hashtag {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  color: #0a3185;
  line-height: 30px;
}

.countdown {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  text-align: center;

  .header {
    font-size: 15px;

    td {
      text-align: center;
    }
  }

  .numbers {
    line-height: 40px;
    font-size: 45px;

    td {
      text-align: center;
    }
  }

  .footer {
    font-size: 18px;

    td {
      text-align: center;
    }
  }
}

div.heading {
  padding-left: 20px;
  padding-right: 20px;
}

div.ranking {

  margin: 20px;
  margin-top: 0px;
  background-color: #d9e5ec;
  padding: 20px;

  .countdown {
    float: none;
    margin: 50px auto;
    width: 310px;
  }
}

.ranking-block {
  width: 47%;
  float: left;
}

.ranking_spacer {
  float: left;
  width: 5%;
}

div.conditions {

  margin: 20px;
  background-color: #fff;

  padding: 20px;

  .level2 {
    padding-left: 30px;
  }
}

div.footer {
  padding-top: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  color: white;

  a {
    color: white;
    text-decoration: none;
    line-height: 2.7em;
  }

  height: 236px;
  margin-bottom: 0;
  background-color: #0a3185;
  width: 100%;

  .links {
    margin: 15px 30px;
  }

  .head {
    font-size: 12px;
  }

  hr {
    width: 30px;
    text-align: left;
    margin-left: 0;
    margin-top: -5;

    height: 1px;
    border-width: 0;
    color: white;
    background-color: white;

  }

  .copyright {
    margin-top: 50px;
  }
}

body.interim-stage {
  div.content {
    min-height: 50%;
    min-height: -webkit-calc(100% - 990px);
    min-height: -moz-calc(100% - 990px);
    min-height: calc(100% - 990px);
  }

  .sportswear2 {
    width: 250px;
  }

  div.ranking {
    max-width: 1300px;
    margin: auto;
    padding-left: 0;
    padding-right: 0;

    .countdown {
      display: block;
    }
  }
}

/* stylelint-disable-next-line block-no-empty */
body.countdown-stage {

}

body.active-stage {
  div.ranking {

    .countdown {
      margin: 20px auto;
    }
  }
}

body.archive-stage {
  div.content {
    min-height: calc(100% - 1245px);
    margin: auto;
    padding-top: 40px;
    padding-bottom: 40px;

    h1 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  div.topbild {
    height: initial;
  }
}

@media (max-width: 1000px) {
  .main-navigation {
    display: none;
  }
  .main-navigation ul li a {
    font-size: 18px;
  }

  div.center-content {
    max-width: inherit;
    margin: inherit;
  }
}

@media (min-width: 600px) and (max-width: 1000px) {

  .ranking_spacer {
    display: none;
  }

  .ranking-block {
    width: inherit;
    float: inherit;
  }

  .logo {
    margin: 15px;
    height: 35px;
  }

  h1 {
    margin-top: 30px;
    font-size: 40px;
  }

  h2 {
    margin-bottom: 10px;
  }

  h3.video {
    font-size: 20px;
  }

  a.bluelink {
    font-size: 20px;
  }

  .center {
    margin: auto;
    width: 600px;
    min-height: 250px;
  }

  div.topbild {

    overflow: hidden;

    height: auto;

    div.inner {
      #hintergrund {
        height: auto;
        width: 100%;
      }
    }

  }

  body.archive-stage {
    div.content {
      min-height: calc(100vh - 1045px);
    }
  }

  div.landingpage {
    .center {
      margin: auto;
      width: 580px;
      min-height: 300px;
    }

    .visual {
      padding-top: 0px;
      margin: 0;
      width: 200px;

      img {
        width: 200px;
      }
    }
  }

  .sportswear1 {
    display: none;
  }

  .sportswear2 {
    display: block;
    float: right;
    margin: auto;
    width: 290px;
    padding-right: 20px;

    img {
      width: 250px;
      margin-bottom: 10px;
    }

    .form-links {
      margin-top: 20px;
      margin-left: 0;

      div {
        display: inline-block;
        vertical-align: top;
      }

      img {
        width: 120px;
        padding-right: 4px;
        margin: 0;
      }
    }
  }

  .text {
    float: none;
    width: 100%;

    h3.first {
      margin-right: 0;
    }
  }

  .visual {

    padding-top: 40px;
    margin: auto;
    width: 200px;

    img {
      width: 200px;
    }
  }

  .countdown {

    .header {
      font-size: 16px;
    }

    .numbers {
      line-height: 30px;
      font-size: 35px;
    }

    .footer {
      font-size: 15px;

    }
  }

  div.heading {
    padding-left: 0;
    padding-right: 0;
  }

  .socialbox {
    margin-right: 20px;
  }

  div.ranking {
    margin: 0;
    padding-bottom: 20px;

  }

  div.conditions {
    margin: 0;
    margin-top: 20px;
    padding: 10px;
  }

  div.footer {

    .links {
      margin: 15px;
    }
  }

}

@media (max-width: 600px) {

  h3.video {
    font-size: 20px;
  }

  .ranking-block {
    width: inherit;
    float: inherit;
  }

  .logo {
    margin: 15px;
    height: 35px;
  }

  div.topbild {

    overflow: hidden;

    height: auto;

    div.inner {
      #hintergrund {
        height: auto;
        width: 100%;
      }

    }
  }

  div.content {

    min-height: 70%;
    min-height: -webkit-calc(100% - 374px);
    min-height: -moz-calc(100% - 374px);
    min-height: calc(100% - 374px);
  }

  body.archive-stage {
    div.content {
      min-height: 200px;
    }
  }

  div.landingpage {
    .center {
      margin: 0;
      width: 100%;
      min-height: 300px;
      padding-left: 0;
      padding-right: 0;
    }

    .visual {

      padding-top: 30px;
      margin: auto;
      width: 200px;
      margin-left: 25px;

      h3 {
        margin-top: 20px;
      }

      img {
        width: 200px;
      }
    }
  }

  h1 {
    margin-top: 40px;
    font-size: 36px;
    padding: 0 20px;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 18px;
    padding: 0 20px;
  }

  h3 {
    font-size: 16px;
  }

  .sportswear1 {
    display: none;
  }

  .sportswear2 {
    display: block;
    float: none;
    width: 90%;
    padding-right: 20px;
    padding-left: 20px;

    .form-links {
      div {
        margin-top: 15px;
      }
    }

    img {
      width: 250px;
    }
  }

  .text {
    float: none;
    width: initial;
    padding-left: 20px;
    padding-right: 20px;

    h3.first {
      margin-right: 0;
    }
  }

  .center {
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    min-height: 250px;
  }

  .visual {

    padding-top: 30px;
    margin: auto;
    width: 150px;

    img {
      width: 150px;
    }
  }

  .countdown {
    margin: auto;
    padding-top: 0px;
    width: 340px;
    margin-left: -10px;

    .header {
      font-size: 16px;
    }

    .numbers {
      line-height: 50px;
      font-size: 50px;
    }

    .footer {
      font-size: 16px;

    }
  }

  .socialbox {
    margin-right: 20px;
  }

  .hashtag {
    font-size: 16px;
  }

  div.ranking {

    margin: 20px;
    padding-bottom: 5px;
    padding-top: 5px;

    .countdown {
      margin: 20px auto;
    }
  }

  div.conditions {

    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0;
    padding: 10px;

  }

  div.heading {
    padding-left: 20px;
    padding-right: 20px;
  }

  div.footer {
    .links {
      margin: 15px;
    }
  }
}

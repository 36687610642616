/* source-sans-pro-700 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src:
    local(""),
    url("../fonts/source-sans-pro-v11-latin-700.woff2") format("woff2"),
    url("../fonts/source-sans-pro-v11-latin-700.woff") format("woff"),
}

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src:
    local(""),
    url("../fonts/roboto-v18-latin-regular.woff2") format("woff2"),
    url("../fonts/roboto-v18-latin-regular.woff") format("woff"),
}


@font-face {
  font-family: roboto-regular;
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
  url("../fonts/roboto-v18-latin-regular.woff2") format("woff2"),
  url("../fonts/roboto-v18-latin-regular.woff") format("woff"),
}

@font-face {
  font-family: roboto-bold;
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Bold"), local("Roboto-Bold"),
  url("../fonts/roboto-v18-latin-500.woff2") format("woff2"),
  url("../fonts/roboto-v18-latin-500.woff") format("woff"),
}

@font-face {
  font-family: roboto-light;
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
  url("../fonts/roboto-v20-latin-300.woff2") format("woff2"),
  url("../fonts/roboto-v20-latin-300.woff") format("woff"),
}

@font-face {
  font-family: source-sans-semi;
  font-style: normal;
  font-weight: 600;
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
  url("../fonts/source-sans-pro-v11-latin-600.woff2") format("woff2"),
  url("../fonts/source-sans-pro-v11-latin-600.woff") format("woff"),
}

@font-face {
  font-family: source-sans-italic;
  font-style: italic;
  font-weight: 400;
  src: local("Source Sans Pro Italic"), local("SourceSansPro-Italic"),
  url("../fonts/source-sans-pro-v11-latin-italic.woff2") format("woff2"),
  url("../fonts/source-sans-pro-v11-latin-italic.woff") format("woff"),
}

@font-face {
  font-family: source-sans-bold;
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
  url("../fonts/source-sans-pro-v11-latin-700.woff2") format("woff2"),
  url("../fonts/source-sans-pro-v11-latin-700.woff") format("woff"),
}